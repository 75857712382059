<template>
  <a-modal
    title="操作"
    style="top: 20px;"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false">
    <a-form-model :model="rowData" :rules="formRule" ref="form" layout="horizontal" :label-col="{span:6}" :wrapper-col="{span:18}">
      <a-form-model-item label="选择经销商" >
        <a-select placeholder="请选择经销商" v-model="rowData.dealerId" @change="onChange">
          <a-select-option :value="item.id" v-for="(item, index) in templateList" :key="index">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="角色名称">
        <a-input v-model="rowData.name" placeholder="起一个名字"/>
      </a-form-model-item>
      <a-form-model-item label="角色说明" >
        <a-input v-model="rowData.remark" placeholder="请输入"/>
      </a-form-model-item>
      
      <a-divider />
      <a-form-model-item label="拥有权限" v-if="permissions.length > 0">
        <div style="max-height: 500px;overflow-y: scroll">
          <a-tree
            checkable
            v-model="checkedKeys"
            @check="onCheck"
            :treeData="permissions"
          >
          </a-tree>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addDealerRole, editDealerRole, selectByIdDealerRole } from '../api/DealerRoleApi'

export default {
  name: 'RoleModal',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      mdl: {},
      rowData: {},
      form: this.$form.createForm(this),
      permissions: [],
      treeCheck: false,
      pidSet: [],
      checkedKeys: [],
      halfCheckedKeys: [],
      templateList: [],
      // 表单验证
      formRule: {
        dealerName: [
          { required: true, message: '请选择经销商', trigger: 'change' }
        ],
        customerName: [
          { required: true, message: '请输入客户名', trigger: 'blur' }
        ],
        customerContact: [
          { required: true, pattern: new RegExp(/^1[3456789]\d{9}$/ || /^[0][1-9]{2,3}-[0-9]{5,10}$/), message: '请输入客户联系方式', trigger: 'blur' }
        ],
      },
    }
  },
  created () {
  },
  methods: {
    // 获取行数据
    setRowData(row, handle){
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.axios.get('/api/dealer/dealer/dealerInfo/listAll').then(res => {
        this.templateList = res.body
      })
      if(handle == 'add') {
        this.form.resetFields()
        this.checkedKeys = []
        this.pidSet = []
      }else if(handle == 'edit') {
        if(row.id) {
          this.loadPermissions(row.dealerId)
        }
        selectByIdDealerRole(row.id).then(res => {
          this.rowData = res.body
        })
        this.axios.get('/api/dealer/dealer/dealerPermission/selectRoleId/' + row.id).then(res => {
          const data = res.body
          const pidSet = new Set(data.map(m => m.parentId).filter(id => id > 0))
          console.log(111, pidSet)
          this.pidSet = pidSet
          // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
          this.checkedKeys = data.map(m => m.id).filter(id => !pidSet.has(id))
          console.log(222, this.checkedKeys)
        })  
      } 
    },
    
    onCheck (checkedKeys, info) {
      if (!this.treeCheck) this.treeCheck = true
      this.checkedKeys = checkedKeys
      this.halfCheckedKeys = info.halfCheckedKeys
    },
    onChange(val) {
      this.loadPermissions(val)
    },
    loadPermissions (id) {
      this.permissions = []
      this.axios.get('/api/base/dealer/dealerTemplate/listAllById/' + id).then(res => {
        this.buildtree(res.body, this.permissions, 0)
      })
    },
    buildtree (list, permissions, parentId) {
      list.forEach(item => {
        if (item.parentId == parentId) {
          let child = {
            key: item.menuId,
            title: item.menuName,
            children: []
          }
          this.buildtree(list, child.children, item.menuId)
          permissions.push(child)
        }
      })
      console.log(333, this.permissions)
    },
    handleOk (e) {
      const _this = this
      // 如果没有check过，半选节点是拿不到的，只能通过预先设置的pidSet获取
      const checkedAll = this.treeCheck ? _this.checkedKeys.concat(_this.halfCheckedKeys) : _this.checkedKeys.concat(Array.from(_this.pidSet))
      if (!checkedAll.length > 0) {
        _this.$message.warning('请至少选择一个权限')
        return
      }
      // 触发表单验证
      this.$refs.form.validate(async valid => {
        if (!valid) {
            return
        }
        this.rowData.permissionIds = checkedAll
        this.confirmLoading = true
        const res = this.handle === 'add' ? await addDealerRole(this.rowData) : await editDealerRole(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.confirmLoading = false
          this.rowData = {}
        } else {
          this.confirmLoading = false
          this.$notification.error({ message: res.message })
        }
      })
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
